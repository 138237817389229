@charset "utf-8";

.bl-master-list {
	clear: both;
	height: 65vh;
	border-top: 1px solid #d7d7d7;
	font-size: 0.8rem;
	padding: 20px;
	overflow-y: auto;
	h4 {
			font-size: 0.8rem;
			font-weight: 700;
			margin-bottom: 15px;
		}
	.bl-master-info {
		>h4 {
			float: left;
		}
	.bl-master-info-check {
		float: right;
		label {
			&:last-child {
				margin-left: 20px;
			}
			input[type="checkbox"] {
				vertical-align: middle;
			}
		}
	}
}
}

.bl-master-info-input {
	clear: both;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			line-height: 30px;
			padding-top: 7px;
		}
		&:nth-child(even) {
			margin: 7px 0;
		}
	}
	input[type="text"],
	select {
		height: 30px;
		padding-left: 20px;
		border: 1px solid #d7d7d7;
	}
	.bl-master-info-ul1 {
		width: 45%;
		p {
			min-width: 40%;
		}
	}
	.bl-master-info-ul-select {
		width: 52%;
		button {
			min-width: 15%;
			height: 30px;
			color: #076177;
			border: 1px solid #d7d7d7;
			margin-top: 7px;
			margin-left: 5px;
			&:hover {
				color: #fff;
				background-color: #076177;
			}
		}
	.bl-master-info-ul2 {
		li {
			p {
				min-width: 45%;
			}
			&:first-child {
				input[type="text"] {
					width: 28%;
				}
			}
			&:last-child {
				select {
					width: 50%;
				}
			}
		}
	}
	}
}

.bl-header {
	padding-top: 20px;
			.bl-shipper-name-address,
			.bl-consignee-name-address,
			.bl-notify-name-address {
				clear: both;
				input[type="text"] {
					width: 100%;
					height: 30px;
					line-height: 30px;
					padding-left: 20px;
					margin-top: 10px;
					background-color: #edf1f2;
				}
				textarea {
					width: 100%;
					height: 200px;
					padding: 20px;
					margin: 10px 0;
					background-color: #edf1f2;
				}
			}
			.bl-consignee-name-address,
			.bl-notify-name-address {
				p {
					float: left;
				}
				label {
					float: right;
					input[type="checkbox"] {
						margin-left: 10px;
						vertical-align: middle;
					}
				}
			}
	}

.bl-shipment-info {
	h4 {
		margin: 10px 0;
	}
	ul {
		width: 100%;
		li {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 3px 0;
			p {
				height: 25px;
				min-width: 20%;
				margin-top: 10px;
			}
			.ant-select {
				min-width: 75%;
			}
			.ant-input,
			.ant-picker {
				width: 35%;
			}
			&:nth-child(7) {
				padding-top: 6px;
				margin-bottom: 10px;
				p {
					line-height: 0.9rem;
					margin-top: 0px;
				}
			}
		}
	}
	.bl-shipment-info-select {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 20px;
		li {
			&:first-child,
			&:last-child {
				width: 28%;
				.ant-select {
					min-width: 45%;
				}
			}
			&:nth-child(2) {
				width: 40%;
				margin: 0 10px;
				.ant-select {
				min-width: 65%;
				}
			}
		}
	}
}

.bl-cargo-info {
	ul {
		margin-bottom: 50px;
		li {
			display: flex;
			justify-content: space-between;
			margin: 10px 0;
			&:nth-child(2) {
				.ant-input {
					width: 50%;
				}
				.ant-select {
					width: 23%;
					margin-left: 20px;
				}
			}
			p {
				min-width: 25%;
				line-height: 30px;
			}
		}
	}
}

.bl-description {
	width: 100%;
	div {
		textarea {
			height: 80px;
			padding: 20px;
		}
	}
}

.bl-container-list {
	margin-top: 20px;
		.bl-container-list-h4-btn {
			display: flex;
			justify-content: space-between;
			.bl-container-list-btn {
				display: flex;
				p,
				button {
					width: 50px;
					height: 30px;
					color: #076177;
					border: 1px solid #d7d7d7;
				}
				p {
					line-height: 30px;;
					text-align: center;
					margin-right: 10px;
				}
				button {
					&:hover {
						color: #fff;
						background-color: #076177;
					}
				}
			}
		}
		table {
			width: 100%;
			margin-top: 20px;
			border: 1px solid #d7d7d7;
			thead {
				height: 30px;
				line-height: 30px;
				text-align: center;
				background-color: #edf1f2;
				td {
					border-right: 1px solid #d7d7d7;
					&:first-child {
						width: 5%;
						span {
							display: none;
						}
					}
					&:nth-child(2) {
						width: 35%;
					}
					&:nth-child(3) {
						width: 30%;
					}
					&:nth-child(4),
					&:nth-child(5) {
						width: 10%;
					}
				}
			}
		}
		tbody {
			border-top: 1px solid #d7d7d7;
			tr {
				height: 30px;
				line-height: 30px;
				td {
					padding: 0 5px;
					border-right: 1px solid #d7d7d7;
					&:first-child {
						width: 5%;
					}
					&:nth-child(2) {
						width: 35%;
					}
					&:nth-child(3) {
						width: 30%;
					}
					&:nth-child(4),
					&:nth-child(5) {
						width: 10%;
					}
					.ant-input {
						height: 25px;
					}
					select {
						width: 100%;
						border: 1px solid #d9d9d9;
					}
				}
			}
		}
	}