@charset "utf-8";

#footer {
  clear: both;
  @include flex-center;
  width: 100%;
  height: 160px;
  font-size: 0.6rem;
  background-color: #e5e5e6;
  padding: 0 20px;
  p, address, ul {
    font-size: 0.8rem;
    margin-bottom: 5px;
    word-spacing: 0.2em;
    color: #292929;
  }

  .footer-company {
    display: flex;
  	align-items: center;
    p:nth-child(2){
      &:before,
      &::after{
      content: "|";
      margin:0 10px;
      }
    }
		p:nth-child(4) {
			&:before {
				content: "|";
      	margin:0 10px;
			}
		}
  }

  .footer-address {
		display: flex;
		align-items: center;
    flex-flow:row;
    address {
      &:after {
        content: "|";
        margin:0 10px;
      }
    }
  }

  hr {
    width: 100%;
    border: solid 0.3px #d8d8d8;
    margin-top: 15px;
  }

  ul {
		display: flex;
		align-items: center;
		padding: 10px 0;
		li {
			a {
				&:hover {
					color: #086277;
				}
			}
		}
    li:not(:last-child) {
      &:after {
        content: "|";
        margin:0 10px;
      }
    }
  }

}