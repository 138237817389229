@charset "utf-8";

// 2023.04.11 login 작업 시작
.login-wrap {
	width: 100%;
	height: 100vh;
	font-size: 12px;
	background: url(../images/login-background.png) no-repeat center/cover;
}
.login {
	@include image-center;
	@include flex-center;
	background: url(../images/login.png) no-repeat center;
	background-size: 600px;
	h1 {
		color: #086277;
		font-size: 2.2rem;
		font-weight: 700;
		margin-left: -320px;
		margin-bottom: 40px;
	}
	input[type="text"],
	input[type="password"]{
		border: 0;
		width: 400px;
		height: 55px;
		font-size: 1rem;
		padding-left: 20px;
		background-color: #e7fbfc;
		&::placeholder {
			font-size: 1rem;
			color: #086277;
		}
	}
	input[type="password"] {
		margin: 10px 0;
	}
	button {
		text-align: center;
		font-size: 1.3rem;
		font-weight: 700;
		color: #fff;
		width: 400px;
		height: 65px;
		border-radius: 50px;
		background-color: #086277;
		margin-top: 30px;
		&:hover {
			background-color: #72b6c5;
		}
	}
}
