@charset "utf-8";

// hay frand, play with these vars.
$loader-style: double;
$loader-width: 0.5em;
$loader-color: #ecf0f1;
$loader-size: 150px;
$loader-speed: 1s;

$body-bg: rgba(114, 182, 197, 0.5);

.lodding {
	@include flex-center;
	width: 100%;
	height: 100vh;
	background: $body-bg;
	position: fixed;
	top: 0;
	z-index: 1100;
	.loader {
		width: 1em;
		height: 1em;
		font-size: $loader-size;
		position: relative;
		@media (max-width: $loader-size * 1.5), (max-height: $loader-size * 1.5) {
			font-size: calc($loader-size / 2);
		}
		&:before, &:after {
			content: "";
			top: 0;
			display: block;
			width: 1em;
			height: 1em;
			position: absolute;
			border-width: $loader-width;
			border-style: $loader-style;
			border-color: transparent;
			box-sizing: border-box;
			border-radius: 1em;
			animation: spin $loader-speed infinite;
		}
		&:after {
			left: 0;
			border-left-color: $loader-color;
		}
		&:before {
			right: 0;
			border-right-color: $loader-color;
			animation-delay: calc($loader-speed / -4);
		}
	}

	@keyframes spin {
		from {
			transform: rotate(360deg);
		}
	}
}


// 로딩 중 스크롤 방지

body.loading-scroll-stop {
	overflow: hidden!important
}