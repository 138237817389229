@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
/* You can add global styles to this file, and also import other style files */

/** ag-grid 관련 */
.ag-theme-alpine {
  /** 컬럼구분자 노출 */
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 30%;
}
.ag-header-cell {
  .ag-header-cell-label {
    /** 헤더 가운데 정렬 */
    justify-content: center;
  }
}
.ag-header-group-cell {
  .ag-header-group-cell-label {
    /** 헤더 가운데 정렬 */
    justify-content: center;
  }
}
