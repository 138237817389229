@charset "UTF-8";

// common ////////////////////////////////
.sr-master {
	float: left;
	width: 100%;
	.sr-master-wrap {
		width: 100%;
		padding: 0 20px;
		h2 {
			width: 100%;
			height: 60px;
			line-height: 60px;
			font-size: 1.2rem;
			font-weight: 700;
			margin: 70px auto 30px auto;
		}
		h3, h4 {
			font-size: 1rem;
			font-weight: 700;
			margin: 15px 0;
		} /////////////////////////////////////
		.sr-master-left {
			float: left;
			width: 40%;
			height: 95vh;
			font-size: 0.8rem;
			overflow-y: auto;
			padding: 1px 20px 30px 20px;
			margin-bottom: 50px;
			.sr-master-file-wrap {
				display: flex;
				flex-direction: column;
				height: 250px;
				background-color: #fff;
				border-radius: 5px;
				box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
				p {
					text-align: center;
					font-size: 1rem;
					font-weight: 700;
					padding: 30px 0;
					border-bottom: 1px solid #d7d7d7;
					img {
						vertical-align: middle;
						margin-left: 15px;
					}
				}
				button {
					margin: 50px 0;
				}
			}
			.sr-master-upload {
				p {
					height: 30px;
					line-height: 30px;
					background-color: #fff;
					border: 1px solid #d7d7d7;
					margin-top: 10px;
					padding-left: 20px;
				}
				button {
					height: 30px;
					line-height: 30px;
					color: #fff;
					float: right;
					margin: 10px 0;
					padding: 0 15px;
					background-color: #71b6c5;
					border-radius: 5px;
				}
			}
			.sr-info {
			clear: both;
			margin-top: 30px;
			background-color: #fff;
			border-radius: 5px;
			box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
			cursor: pointer;
			.sr-info-title-wrap {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 0;
				border-bottom: 1px solid #d7d7d7;
				p {
					padding-left: 20px;
					font-weight: 700;
					img {
						vertical-align: middle;
						margin-right: 15px;
					}
					span {
						color: #71b6c5;
					}
				}
				button {
					width: 100px;
					height: 30px;
					color: #fff;
					background: url(../images/sr-icon4.png) no-repeat 90%;
					background-color: #71b6c5;
					border-radius: 5px;
					margin-right: 20px;
					&:disabled {
						background-color: #ccc;
						cursor: not-allowed;
					}
				}
			}
			ul {
				padding: 10px 20px;
				li {
					padding: 20px 0;
					border-bottom: 1px solid #d7d7d7;
					&:last-child {
						border-bottom: 0px solid #d7d7d7;
					}
				}
			}
			&.active {
				border: 1.5px solid #71b6c5;
			}
		}
		}
		.sr-master-right {
			float: right;
			width: 59%;
			height: 95vh;
			font-size: 0.8rem;
			background-color: #fff;
			border-radius: 5px;
			box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
			padding: 30px 20px;
			margin-bottom: 50px;
			overflow-y: auto;
			h3 {
				float: left;
			}
			>button {
				float: right;
				width: 100px;
				height: 30px;
				border: 1px solid #d7d7d7;
				margin-bottom: 20px;
				&:hover {
					color: #fff;
					background-color: #076177;
				}
			}
			.sr-shipper-name-address,
			.sr-consignee-name-address,
			.sr-notify-name-address {
				clear: both;
				p {
					margin: 15px 0;
				}
				input[type="text"] {
					width: 100%;
					height: 30px;
					line-height: 30px;
					padding-left: 20px;
					margin-top: 10px;
					background-color: #edf1f2;
				}
				textarea {
					width: 100%;
					height: 200px;
					padding: 20px;
					margin: 10px 0;
					// border: 0; 주석처리
					// background-color: #edf1f2; 주석처리
					&:hover {
						border: 1px solid #d7d7d7;
					}
				}
			}
			.sr-consignee-name-address,
			.sr-notify-name-address {
				p {
					float: left;
				}
				label {
					float: right;
					input[type="checkbox"] {
						margin-left: 10px;
						vertical-align: middle;
					}
				}
			}
		}
		.sr-shipment-info {
			ul {
				width: 100%;
				li {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					padding: 10px 0;
					p {
						line-height: 30px;
						&:first-child {
							min-width: 30%;
							margin-top: 7px;
						}
					}
					.ant-select {
						min-width: 70%;
					}
					&:nth-child(6) {
						.ant-input {
							width: 34%;
							&:last-child {
								margin-left: 1%
							}
						}
					}
					&:nth-child(7) {
						p {
							line-height: 1.2rem;
						}
						.ant-picker {
							min-width: 34%;
							&:last-child {
								margin-left: 1%;
							}
						}
					}
				}
			}
			.sr-shipment-info-select {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin-bottom: 20px;
				li {
					width: 28%;
					p {
					}
					.ant-select {
						min-width: 38.5%;
					}
					&:nth-child(2) {
						width: 40%;
						margin: 0 10px;
						.ant-select {
							min-width: 60%;
						}
					}
				}
			}
		}
		.sr-cargo-info {
			padding-top: 10px;
			border-top: 1px solid #d7d7d7;
			ul {
				margin-bottom: 50px;
				li {
					display: flex;
					justify-content: space-between;
					margin: 10px 0;
					p {
						min-width: 30%;
						line-height: 30px;
					}
					.ant-input {
						width: 70%;
					}
					&:nth-child(2) {
						.ant-input{
							// min-width: 35%;

						}
						.ant-select {
							min-width: 20%;
							margin-left: 10px;
						}
					}
				}
			}
			.sr-result {
				width: 100%;
				display: flex;
				justify-content: space-between;
				textarea {
					height: 200px;
					&:first-child {
						width: 20%;
					}
					&:last-child {
						width: 79%;
					}
				}
			}
		}
		.sr-container-list {
			margin-top: 20px;
				.sr-container-list-h4-btn {
					display: flex;
					justify-content: space-between;
					.sr-container-list-btn {
						display: flex;
						p,
						button {
							width: 50px;
							height: 30px;
							border: 1px solid #d7d7d7;
						}
						p {
							line-height: 30px;;
							text-align: center;
							margin-right: 10px;
						}
					}
				}
				table {
					width: 100%;
					margin-top: 20px;
					border: 1px solid #d7d7d7;
					thead {
						height: 30px;
						line-height: 30px;
						text-align: center;
						background-color: #edf1f2;
						td {
							border-right: 1px solid #d7d7d7;
							&:first-child {
								width: 5%;
								span {
									display: none;
								}
							}
							&:nth-child(2) {
								width: 35%;
							}
							&:nth-child(3) {
								width: 30%;
							}
							&:nth-child(4),
							&:nth-child(5) {
								width: 10%;
							}
						}
					}
				}
				tbody {
					tr {
						height: 30px;
						line-height: 30px;
						td {
							padding: 0 5px;
							border-right: 1px solid #d7d7d7;
							&:first-child {
								width: 5%;
							}
							&:nth-child(2) {
								width: 35%;
							}
							&:nth-child(3) {
								width: 30%;
							}
							&:nth-child(4),
							&:nth-child(5) {
								width: 10%;
							}
							.ant-input {
								height: 25px;
							}
							select {
								width: 100%;
								border: 1px solid #d9d9d9;
							}
						}
					}
				}
			}
	}
}