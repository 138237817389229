@charset "utf-8";

.corechat {
	position: relative;
	top: 0;
	right: 0;
	width: 22vw;
	height: 100vh;
	z-index: 19;
	background-color: #f5f5f5;
	overflow: hidden;
	.corechat-wrap {
		width: 100%;
		height: 100%;
		float: right;
		border-left: 1px solid #d7d7d7;
		background-color: #fff;
	}
	.chat-character {
		height: 60px;
		line-height: 60px;
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
		background-color: #e9f5f7;
		p {
			font-weight: 700;
			span {
				font-size: 1.2rem;
			}
			img {
				width: 30px;
				margin-right: 10px;
				vertical-align: -5px;
			}
			span {
				color: #086277;
			}
		}
		button {
			img {
				width: 80%;
			}
		}
	}
	.chatting {
		clear: both;
		width: 100%;
		height: calc(100vh - 110px);
		position: relative;
		font-size: 0.8rem;
		line-height: 1.2rem;
		background-color: #fff;
		overflow-y: auto;
		.corechat-character2 {
			padding-left: 10px;
			p {
				img {
					width: 35px;
				}
			}
		}
		.chatting-bot {
			width: 95%;
			float: left;
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
			.user-character {
					display: none;
			}
			div {
				position: relative;
				width: 100%;
				padding: 10px;
				margin: 20px 0 20px 10px;
				border-radius: .4em;
				background-color: #edf1f2;
				word-break: break-all;
				&::after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 1%;
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-right-color: #edf1f2;
					border-left: 0;
					border-bottom: 0;
					margin-top: -4px;
					margin-left: -6px;
				}
			}
		}
		.chatting-question {
			width: 95%;
			float: right;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			.bot-character {
				display: none;
			}
			.user-character {
				float: right;
				margin-right: 10px;
			}
			div {
				width: 100%;
				position: relative;
				padding: 10px;
				margin: 20px 10px 20px 0;
				border-radius: .4em;
				background-color: #b9e3ed;
				word-break: break-all;
				&::after {
					content: '';
					position: absolute;
					right: 0;
					bottom: 1%;
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-left-color: #b9e3ed;
					border-right: 0;
					border-bottom: 0;
					margin-top: -4px;
					margin-right: -6px;
				}
			}
		}
	}
}

.chat-text {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		display: flex;
  	flex-direction: column;
		background-color: #fff;
	.chat-menu-bl-wrap {
		height: 170px;
		.chat-menu {
			width: 100%;
			height: 120px;
			font-size: 0.8rem;
			background-color: #fff;
			border-top: 1px solid #d7d7d7;
			>button {
				float: right;
				color: #d7d7d7;
				font-size: 1.2rem;
				font-weight: 700;
				margin: 5px;
			}
			ul {
				clear: both;
				padding: 30px 10px 0 10px;
				display: flex;
				justify-content: space-between;
				font-size: 0.7rem;
				li {
					width: 16.66%;
					text-align: center;
					button {
						width: 35px;
						height: 35px;
						border-radius: 50px;
						background-color: #71b6c5;
						padding: 10px 0;
						img {
							width: 17px;
							padding-bottom: 15px;
						}
					}
					p {
						line-height: 0.8rem;
					}
				}
			}
		}
		.chat-bl-text {
			display: flex;
			justify-content: space-between;
			font-size: 0.8rem;
			border-top: 1px solid #d7d7d7;
			.ant-select {
				width: 38%;
				margin-left: 10px;
				margin-top: 10px;
			}
			input[type="text"] {
				height: 45px;
				padding-left: 10px;
				&::placeholder {
					font-size: 0.8rem;
				}
			}
			label {
				width: 45px;
				height: 50px;
				background: url(../images/file.png) no-repeat 35%;
				background-size: 50%;
				cursor: pointer;
			}
			input[type="file"] {
				display: none;
			}
		}
	}
	.chat-mes-text {
		position: relative;
		display: flex;
		justify-content: space-between;
		font-size: 0.8rem;
		border-top: 1px solid #d7d7d7;
		background-color: #fff;
		z-index: 2000;
		button {
			width: 50px;
			line-height: 50px;
			img {
				width: 15px;
				vertical-align: middle;
			}
		}
		input[type="text"] {
			width: 70%;
			height: 50px;
			border: 0;
			outline: none;
			padding-left: 10px;
			&::placeholder {
				font-size: 0.8rem;
			}
		}
	}
}
