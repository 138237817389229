@charset "utf-8";

// common //////////////////////////////
.to-do-list {
	float: left;
	width: 100%;
	margin-bottom: 30px;
	.to-do-list-wrap {
		width: 100%;
		padding: 0 20px;
		h2 {
			width: 100%;
			height: 60px;
			line-height: 60px;
			font-size: 1.2rem;
			font-weight: 700;
			margin: 70px auto 30px auto;
		}
		h3 {
			font-size: 1rem;
			font-weight: 700;
			margin-bottom: 15px;
			padding-left: 20px;
		}
	}
} //////////////////////////////////////


.status-issue-wrap {
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
	padding: 20px 10px 10px 10px;
	ul {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 0.8rem;
		padding: 20px 15px;
		li {
			height: 70px;
			border-radius: 5px;
			border: 1px solid #d9d9d9;
			background-color: #fff;
			cursor: pointer;
			>div {
				display: flex;
				flex-direction: row;
				>p {
					width: 50px;
					height: 70px;
					border-right: 1px solid #e5e5e5;
					text-align: center;
					padding-top: 23px;
					img {
						width: 25px;
					}
				}
				div {
					width: 100px;
					text-align: center;
					margin: 0 auto;
					padding-top: 15px;
					p{
						&:first-child {
							font-size: 1.2rem;
							font-weight: 700;
							color: #086277;
							padding-bottom: 5px;
						}
						&:last-child {
							line-height: 1rem;
						}
					}
				}
			}
			&:nth-child(4) {
				div {
					p {
						img {
							width: 30px;
						}
					}
				}
			}
			&:nth-child(5),
			&:nth-child(6), {
				div {
					p {
						img {
							width: 28px;
						}
					}
				}
			}
			&:nth-child(8) {
				div {
					p {
						padding-top: 18px;
					}
				}
			}
		}
	}
	.my-shipping-status {
		width: 63.2%;
			li {
				width: 19%;
			}
	}
	.issue {
		width: 35%;
			li {
				width: 31.8%;
			}
	}
}

.vessel-issue-wrap {
	display: flex;
	justify-content: space-between;
	padding: 30px 0;
	.vessel-position {
		width: 49.2%;
		height: 78vh;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
		padding: 30px 10px;
		div {
			width: 100%;
			height: 100%;
			padding: 20px 0 50px 0;
			ul {
				display: flex;
				justify-content: space-between;
				padding: 0 15px 20px 15px;
				li {
					display: flex;
					flex-direction: row;
					border-radius: 5px;
					border: 1px solid #d9d9d9;
					&:first-child,
					&:nth-child(2) {
						width: 42%;
						height: 50px;
					}
					&:last-child {
						align-items: center;
						justify-content: center;
						width: 15%;
						height: 50px;
						p {
							color: #086277;
							text-align: center;
						}
					}
					p {
						font-size: 0.8rem;
						line-height: 1rem;
						padding: 10px;
						&:first-child {
							font-weight: 700;
						}
					}
				}
			}
			.map {
				position: relative;
				width: 100%;
				height: 85%;
				background: url(../images/map.gif) no-repeat center;
				background-size: 96.5%;
				padding: 20px 15px 0 15px;
				button {
					position: absolute;
					top: 35%;
					left: 50%;
					margin-left: 50px;
					&:nth-child(2) {
						top: 70%;
						left: 35%;
					}
					&:last-child {
						top: 55%;
						left: 65%;
					}
					&.active {
						border: 2px dashed #fff;
					}
				}
			}
		}
	}
	.issue-list {
		width: 49.2%;
		height: 78vh;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
		padding: 30px 10px;
		>div {
			height: 100%;
			font-size: 0.7rem;
			padding: 20px 15px 12px 15px;
			overflow-y: auto;
			table {
				width: 100%;
				border-top: 1px solid #d8d8d8;
				border-left: 1px solid #d8d8d8;
				thead {
					height: 40px;
					line-height: 40px;
					text-align: center;
					font-weight: 700;
					background-color: #e9f5f7;
					tr {
						border-bottom: 1px solid #d8d8d8;
						td {
							border-right: 1px solid #d8d8d8;
						}
					}
				}
				tbody {
					text-align: center;
					background-color: #fff;
					tr {
						height: 40px;
						line-height: 40px;
						td {
							border-right: 1px solid #d8d8d8;
							border-bottom: 1px solid #d8d8d8;
						}
					}
				}
			}
		}
	}
	.to-do-list-pagenav {
		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 20px;
			li {
				&:nth-child(2) {
					margin: 0 10px;
				}
			}
		}
	}
}


