@charset "utf-8";

// common //////////////////////////////
.admin-dashboard {
	float: left;
	width: 100%;
	.admin-dashboard-wrap {
		width: 100%;
		padding: 0 20px;
		h2 {
			width: 100%;
			height: 60px;
			line-height: 60px;
			font-size: 1.2rem;
			font-weight: 700;
			margin: 70px auto 30px auto;
		}
		h3 {
			font-size: 1rem;
			font-weight: 700;
			margin: 40px 0;
		}
	}
} //////////////////////////////////////

.admin-dashboard-select {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
	padding: 30px 25px;
	ul {
		display: flex;
		justify-content: space-between;
		font-size: 0.8rem;
		li {
			display: flex;
			justify-content: space-between;
			width: 24%;
			height: 50px;
			line-height: 50px;
			padding: 0 10px;
			border: 1px solid #d7d7d7;
			p {
				width: 35%;
			}
			.ant-picker {
				min-width: 75%;
				height: 33px;
				margin-top: 7px;
			}
			.ant-select {
				min-width: 75%;
			}
		}
	}
}


.admin-dashboard-first {
	padding-bottom: 50px;
	.current-issue {
		display: flex;
		width: 100%;
		overflow: hidden;
		.current-issue1 {
			min-width: 32.5%;
			font-size: 0.8rem;
			background-color: #fff;
			border-radius: 5px;
			box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
			margin: 10px 1px;
			&:not(:first-child) {
			margin-left: 1%;
			}
			>p {
				height: 50px;
				line-height: 50px;
				font-size: 1rem;
				font-weight: 700;
				padding-left: 20px;
				span {
					color: #71b6c5;
				}
			}
			ul {
				li {
					border-top: 1px solid #d7d7d7;
					display: flex;
					justify-content: space-between;
					padding: 15px 20px;
					p {
						img {
							width: 20px;
							vertical-align: middle;
							margin-right: 10px;
						}
					}
					&:nth-child(2) {
						p{
							&:nth-child(1) {
								line-height: 40px;
							}
							&:nth-child(2) {
								width: 70%;
								height: 40px;
								text-align: right;
							}
						}
					}
					&:nth-child(4) {
						p {
							&:nth-child(2) {
								width: 70%;
								.ant-select {
									margin-top: -5px;
								}
							}
						}
					}
				}
			}
		}
	}
	.current-issue-arrow {
		width: 110px;
		margin: 20px auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		button {
			.fa-solid {
				font-size: 1.6rem;
				color: #71b6c5;
			}
			&:disabled {
				color: #ccc;
				cursor: not-allowed;
			}
		}
		p {
			span {
				color: #71b6c5;
				font-weight: 700;
			}
		}
	}
}

.admin-dashboard-second {
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
	margin-top: 30px;
	padding: 40px 20px;
	overflow-x: auto;
	.performance-trend,
	.total-revenue,
	.total-cost {
		font-size: 0.8rem;
		width: 32%;
		border: 1px solid red;
		h3 {
			float: left;
		}
	}
}

.admin-dashboard-third {
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
	margin-top: 30px;
	padding: 40px 20px;
	overflow-x: auto;
	.volume-country,
	.volume-carrier,
	.performance-trend-country {
		width: 32%;
		h3 {
			float: left;
		}
	}
}

.admin-dashboard-four {
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
	margin: 30px 0 50px 0;
	padding: 40px 20px 60px 20px;
	overflow-x: auto;
	.performance-comparison,
	.performance-trend-item,
	.performance-status {
		width: 32%;
		h3 {
			float: left;
		}
	}
}

.admin-chart {
	width: 370px;
}
