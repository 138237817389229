@charset "UTF-8";

.sr-send-modal {
	height: 63vh;
	padding: 0 20px 20px 20px;
	overflow-y: auto;
	button {
		float: right;
		width: 100px;
		height: 30px;
		color: #fff;
		background-color: #076177;
		margin-bottom: 20px;
	}
	.sr-send-content {
		clear: both;
		cursor: pointer;
		.sr-send-company-list {
			width: 100%;
			background-color: #edf1f2;
			padding: 10px;
			margin-top: 20px;
			p {
				height: 40px;
				line-height: 40px;
			}
			&.active {
				border: 1.5px solid #076177;
			}
		}
	}
}