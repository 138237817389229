/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:after, *:before {
  box-sizing: border-box;
  pointer-events: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: 0;
  background: none;
  font-size: 0.8rem;
  cursor: pointer;
}

input {
  border: 0;
  outline: none;
  font-size: 0.8rem;
}

select {
  outline: none;
  font-size: 0.8rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #72b6c6;
  border: 0 none transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: #72b6c6;
}
::-webkit-scrollbar-thumb:active {
  background: #72b6c6;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
  border: 0 none transparent;
}
::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}
::-webkit-scrollbar-track:active {
  background: #e0e0e0;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

#content-corechat {
  min-width: 1500px;
  min-height: 100vh;
  display: flex;
  overflow: auto;
}

#content {
  position: relative;
  top: 0;
  left: 0;
  width: 78vw;
  height: 100vh;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #f5f5f5;
}

.lodding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(114, 182, 197, 0.5);
  position: fixed;
  top: 0;
  z-index: 1100;
}
.lodding .loader {
  width: 1em;
  height: 1em;
  font-size: 150px;
  position: relative;
}
@media (max-width: 225px), (max-height: 225px) {
  .lodding .loader {
    font-size: 75px;
  }
}
.lodding .loader:before, .lodding .loader:after {
  content: "";
  top: 0;
  display: block;
  width: 1em;
  height: 1em;
  position: absolute;
  border-width: 0.5em;
  border-style: double;
  border-color: transparent;
  box-sizing: border-box;
  border-radius: 1em;
  animation: spin 1s infinite;
}
.lodding .loader:after {
  left: 0;
  border-left-color: #ecf0f1;
}
.lodding .loader:before {
  right: 0;
  border-right-color: #ecf0f1;
  animation-delay: -0.25s;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }
}

body.loading-scroll-stop {
  overflow: hidden !important;
}

.login-wrap {
  width: 100%;
  height: 100vh;
  font-size: 12px;
  background: url(../images/login-background.png) no-repeat center/cover;
}

.login {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../images/login.png) no-repeat center;
  background-size: 600px;
}
.login h1 {
  color: #086277;
  font-size: 2.2rem;
  font-weight: 700;
  margin-left: -320px;
  margin-bottom: 40px;
}
.login input[type=text],
.login input[type=password] {
  border: 0;
  width: 400px;
  height: 55px;
  font-size: 1rem;
  padding-left: 20px;
  background-color: #e7fbfc;
}
.login input[type=text]::placeholder,
.login input[type=password]::placeholder {
  font-size: 1rem;
  color: #086277;
}
.login input[type=password] {
  margin: 10px 0;
}
.login button {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
  width: 400px;
  height: 65px;
  border-radius: 50px;
  background-color: #086277;
  margin-top: 30px;
}
.login button:hover {
  background-color: #72b6c5;
}

#header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}
#header .header-wrap {
  display: flex;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #d5d5d5;
  overflow: hidden;
  background-color: #fff;
}
#header .header-wrap h1 {
  margin: 15px 80px 0 20px;
}
#header .header-wrap h1 img {
  width: 150px;
}
#header .header-wrap .gnb {
  display: flex;
  font-size: 1rem;
  margin-top: 17px;
  background-color: #fff;
}
#header .header-wrap .gnb > li {
  font-weight: 700;
  color: #292929;
}
#header .header-wrap .gnb > li > a {
  text-align: center;
  display: block;
  height: 55px;
}
#header .header-wrap .gnb > li > a:hover {
  color: #086277;
}
#header .header-wrap .gnb > li > a.active1 {
  color: #086277;
}
#header .header-wrap .gnb > li .two-depth li {
  font-weight: 500;
  height: 40px;
  line-height: 40px;
}
#header .header-wrap .gnb > li .two-depth li a {
  display: block;
  text-align: center;
}
#header .header-wrap .gnb > li .two-depth li a:hover {
  color: #086277;
}
#header .header-wrap .gnb > li .two-depth li a.active2 {
  color: #086277;
  font-weight: 700;
}
#header .header-wrap .gnb > li:nth-child(2) {
  margin: 0 20px;
}

.util-menu {
  margin-right: 30px;
}
.util-menu ul {
  display: flex;
  flex-direction: row;
  margin-top: 17px;
}
.util-menu ul li {
  font-size: 1rem;
  font-weight: 700;
}
.util-menu ul li:first-child span {
  padding: 0 30px;
}
.util-menu ul li:first-child:after {
  content: "|";
  margin: 0 20px;
}
.util-menu ul li:nth-child(2) {
  margin-right: 30px;
}
.util-menu ul li:nth-child(2) button {
  font-size: 1rem;
  font-weight: 700;
  color: #086277;
}
.util-menu ul li:last-child {
  margin-top: -10px;
}
.util-menu ul li:last-child button {
  width: 120px;
  height: 35px;
  line-height: 35px;
  font-weight: 700;
  color: rgb(232, 244, 255);
  border-radius: 5px;
  background-color: #086277;
}
.util-menu ul li:last-child img {
  display: none;
  width: 20%;
  vertical-align: -10%;
  margin-right: 10px;
}
.util-menu ul li:last-child span {
  font-size: 1rem;
  color: rgb(112, 190, 207);
}

#footer {
  clear: both;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  font-size: 0.6rem;
  background-color: #e5e5e6;
  padding: 0 20px;
}
#footer p, #footer address, #footer ul {
  font-size: 0.8rem;
  margin-bottom: 5px;
  word-spacing: 0.2em;
  color: #292929;
}
#footer .footer-company {
  display: flex;
  align-items: center;
}
#footer .footer-company p:nth-child(2):before, #footer .footer-company p:nth-child(2)::after {
  content: "|";
  margin: 0 10px;
}
#footer .footer-company p:nth-child(4):before {
  content: "|";
  margin: 0 10px;
}
#footer .footer-address {
  display: flex;
  align-items: center;
  flex-flow: row;
}
#footer .footer-address address:after {
  content: "|";
  margin: 0 10px;
}
#footer hr {
  width: 100%;
  border: solid 0.3px #d8d8d8;
  margin-top: 15px;
}
#footer ul {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
#footer ul li a:hover {
  color: #086277;
}
#footer ul li:not(:last-child):after {
  content: "|";
  margin: 0 10px;
}

.corechat {
  position: relative;
  top: 0;
  right: 0;
  width: 22vw;
  height: 100vh;
  z-index: 19;
  background-color: #f5f5f5;
  overflow: hidden;
}
.corechat .corechat-wrap {
  width: 100%;
  height: 100%;
  float: right;
  border-left: 1px solid #d7d7d7;
  background-color: #fff;
}
.corechat .chat-character {
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #e9f5f7;
}
.corechat .chat-character p {
  font-weight: 700;
}
.corechat .chat-character p span {
  font-size: 1.2rem;
}
.corechat .chat-character p img {
  width: 30px;
  margin-right: 10px;
  vertical-align: -5px;
}
.corechat .chat-character p span {
  color: #086277;
}
.corechat .chat-character button img {
  width: 80%;
}
.corechat .chatting {
  clear: both;
  width: 100%;
  height: calc(100vh - 110px);
  position: relative;
  font-size: 0.8rem;
  line-height: 1.2rem;
  background-color: #fff;
  overflow-y: auto;
}
.corechat .chatting .corechat-character2 {
  padding-left: 10px;
}
.corechat .chatting .corechat-character2 p img {
  width: 35px;
}
.corechat .chatting .chatting-bot {
  width: 95%;
  float: left;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.corechat .chatting .chatting-bot .user-character {
  display: none;
}
.corechat .chatting .chatting-bot div {
  position: relative;
  width: 100%;
  padding: 10px;
  margin: 20px 0 20px 10px;
  border-radius: 0.4em;
  background-color: #edf1f2;
  word-break: break-all;
}
.corechat .chatting .chatting-bot div::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #edf1f2;
  border-left: 0;
  border-bottom: 0;
  margin-top: -4px;
  margin-left: -6px;
}
.corechat .chatting .chatting-question {
  width: 95%;
  float: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.corechat .chatting .chatting-question .bot-character {
  display: none;
}
.corechat .chatting .chatting-question .user-character {
  float: right;
  margin-right: 10px;
}
.corechat .chatting .chatting-question div {
  width: 100%;
  position: relative;
  padding: 10px;
  margin: 20px 10px 20px 0;
  border-radius: 0.4em;
  background-color: #b9e3ed;
  word-break: break-all;
}
.corechat .chatting .chatting-question div::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 1%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #b9e3ed;
  border-right: 0;
  border-bottom: 0;
  margin-top: -4px;
  margin-right: -6px;
}

.chat-text {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.chat-text .chat-menu-bl-wrap {
  height: 170px;
}
.chat-text .chat-menu-bl-wrap .chat-menu {
  width: 100%;
  height: 120px;
  font-size: 0.8rem;
  background-color: #fff;
  border-top: 1px solid #d7d7d7;
}
.chat-text .chat-menu-bl-wrap .chat-menu > button {
  float: right;
  color: #d7d7d7;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 5px;
}
.chat-text .chat-menu-bl-wrap .chat-menu ul {
  clear: both;
  padding: 30px 10px 0 10px;
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
}
.chat-text .chat-menu-bl-wrap .chat-menu ul li {
  width: 16.66%;
  text-align: center;
}
.chat-text .chat-menu-bl-wrap .chat-menu ul li button {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: #71b6c5;
  padding: 10px 0;
}
.chat-text .chat-menu-bl-wrap .chat-menu ul li button img {
  width: 17px;
  padding-bottom: 15px;
}
.chat-text .chat-menu-bl-wrap .chat-menu ul li p {
  line-height: 0.8rem;
}
.chat-text .chat-menu-bl-wrap .chat-bl-text {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  border-top: 1px solid #d7d7d7;
}
.chat-text .chat-menu-bl-wrap .chat-bl-text .ant-select {
  width: 38%;
  margin-left: 10px;
  margin-top: 10px;
}
.chat-text .chat-menu-bl-wrap .chat-bl-text input[type=text] {
  height: 45px;
  padding-left: 10px;
}
.chat-text .chat-menu-bl-wrap .chat-bl-text input[type=text]::placeholder {
  font-size: 0.8rem;
}
.chat-text .chat-menu-bl-wrap .chat-bl-text label {
  width: 45px;
  height: 50px;
  background: url(../images/file.png) no-repeat 35%;
  background-size: 50%;
  cursor: pointer;
}
.chat-text .chat-menu-bl-wrap .chat-bl-text input[type=file] {
  display: none;
}
.chat-text .chat-mes-text {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  border-top: 1px solid #d7d7d7;
  background-color: #fff;
  z-index: 2000;
}
.chat-text .chat-mes-text button {
  width: 50px;
  line-height: 50px;
}
.chat-text .chat-mes-text button img {
  width: 15px;
  vertical-align: middle;
}
.chat-text .chat-mes-text input[type=text] {
  width: 70%;
  height: 50px;
  border: 0;
  outline: none;
  padding-left: 10px;
}
.chat-text .chat-mes-text input[type=text]::placeholder {
  font-size: 0.8rem;
}

.to-do-list {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
.to-do-list .to-do-list-wrap {
  width: 100%;
  padding: 0 20px;
}
.to-do-list .to-do-list-wrap h2 {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 70px auto 30px auto;
}
.to-do-list .to-do-list-wrap h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 15px;
  padding-left: 20px;
}

.status-issue-wrap {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 20px 10px 10px 10px;
}
.status-issue-wrap ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 20px 15px;
}
.status-issue-wrap ul li {
  height: 70px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  cursor: pointer;
}
.status-issue-wrap ul li > div {
  display: flex;
  flex-direction: row;
}
.status-issue-wrap ul li > div > p {
  width: 50px;
  height: 70px;
  border-right: 1px solid #e5e5e5;
  text-align: center;
  padding-top: 23px;
}
.status-issue-wrap ul li > div > p img {
  width: 25px;
}
.status-issue-wrap ul li > div div {
  width: 100px;
  text-align: center;
  margin: 0 auto;
  padding-top: 15px;
}
.status-issue-wrap ul li > div div p:first-child {
  font-size: 1.2rem;
  font-weight: 700;
  color: #086277;
  padding-bottom: 5px;
}
.status-issue-wrap ul li > div div p:last-child {
  line-height: 1rem;
}
.status-issue-wrap ul li:nth-child(4) div p img {
  width: 30px;
}
.status-issue-wrap ul li:nth-child(5) div p img, .status-issue-wrap ul li:nth-child(6) div p img {
  width: 28px;
}
.status-issue-wrap ul li:nth-child(8) div p {
  padding-top: 18px;
}
.status-issue-wrap .my-shipping-status {
  width: 63.2%;
}
.status-issue-wrap .my-shipping-status li {
  width: 19%;
}
.status-issue-wrap .issue {
  width: 35%;
}
.status-issue-wrap .issue li {
  width: 31.8%;
}

.vessel-issue-wrap {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}
.vessel-issue-wrap .vessel-position {
  width: 49.2%;
  height: 78vh;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 30px 10px;
}
.vessel-issue-wrap .vessel-position div {
  width: 100%;
  height: 100%;
  padding: 20px 0 50px 0;
}
.vessel-issue-wrap .vessel-position div ul {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 20px 15px;
}
.vessel-issue-wrap .vessel-position div ul li {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.vessel-issue-wrap .vessel-position div ul li:first-child, .vessel-issue-wrap .vessel-position div ul li:nth-child(2) {
  width: 42%;
  height: 50px;
}
.vessel-issue-wrap .vessel-position div ul li:last-child {
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 50px;
}
.vessel-issue-wrap .vessel-position div ul li:last-child p {
  color: #086277;
  text-align: center;
}
.vessel-issue-wrap .vessel-position div ul li p {
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 10px;
}
.vessel-issue-wrap .vessel-position div ul li p:first-child {
  font-weight: 700;
}
.vessel-issue-wrap .vessel-position div .map {
  position: relative;
  width: 100%;
  height: 85%;
  background: url(../images/map.gif) no-repeat center;
  background-size: 96.5%;
  padding: 20px 15px 0 15px;
}
.vessel-issue-wrap .vessel-position div .map button {
  position: absolute;
  top: 35%;
  left: 50%;
  margin-left: 50px;
}
.vessel-issue-wrap .vessel-position div .map button:nth-child(2) {
  top: 70%;
  left: 35%;
}
.vessel-issue-wrap .vessel-position div .map button:last-child {
  top: 55%;
  left: 65%;
}
.vessel-issue-wrap .vessel-position div .map button.active {
  border: 2px dashed #fff;
}
.vessel-issue-wrap .issue-list {
  width: 49.2%;
  height: 78vh;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 30px 10px;
}
.vessel-issue-wrap .issue-list > div {
  height: 100%;
  font-size: 0.7rem;
  padding: 20px 15px 12px 15px;
  overflow-y: auto;
}
.vessel-issue-wrap .issue-list > div table {
  width: 100%;
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}
.vessel-issue-wrap .issue-list > div table thead {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  background-color: #e9f5f7;
}
.vessel-issue-wrap .issue-list > div table thead tr {
  border-bottom: 1px solid #d8d8d8;
}
.vessel-issue-wrap .issue-list > div table thead tr td {
  border-right: 1px solid #d8d8d8;
}
.vessel-issue-wrap .issue-list > div table tbody {
  text-align: center;
  background-color: #fff;
}
.vessel-issue-wrap .issue-list > div table tbody tr {
  height: 40px;
  line-height: 40px;
}
.vessel-issue-wrap .issue-list > div table tbody tr td {
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.vessel-issue-wrap .to-do-list-pagenav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.vessel-issue-wrap .to-do-list-pagenav ul li:nth-child(2) {
  margin: 0 10px;
}

.shipment {
  float: left;
  width: 100%;
}
.shipment .shipment-wrap {
  width: 100%;
  padding: 0 20px;
}
.shipment .shipment-wrap h2 {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 70px auto 30px auto;
}
.shipment .shipment-wrap h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 15px;
  padding-left: 20px;
}
.shipment .shipment-wrap .shipment-search-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.6rem;
  padding: 20px 15px;
}
.shipment .shipment-wrap .shipment-search-list li {
  width: 19.5%;
  height: 90px;
  border: 1px solid #d7d7d7;
}
.shipment .shipment-wrap .shipment-search-list li:nth-child(n+6) {
  margin-top: 12px;
}
.shipment .shipment-wrap .shipment-search-list li > div {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  margin-top: 5px;
}
.shipment .shipment-wrap .shipment-search-list li > div p {
  line-height: 30px;
  text-align: center;
  font-size: 1rem;
  margin-top: 6px;
}
.shipment .shipment-wrap .shipment-search-list li:first-child div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.shipment .shipment-wrap .shipment-search-list li:first-child div .ant-picker {
  width: 100%;
  margin-top: 6px;
}
.shipment .shipment-wrap .shipment-search-list li:first-child div .ant-picker-input input {
  width: 50%;
}
.shipment .shipment-wrap .shipment-search {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 30px 10px 80px 10px;
}
.shipment .shipment-wrap .shipment-search-btn {
  float: right;
  padding: 20px 0;
  font-size: 0.8rem;
}
.shipment .shipment-wrap .shipment-search-btn label {
  margin-right: 10px;
}
.shipment .shipment-wrap .shipment-search-btn label input[type=checkbox] {
  vertical-align: middle;
  margin-right: 10px;
}
.shipment .shipment-wrap .shipment-search-btn > button {
  width: 80px;
  height: 30px;
  line-height: 25px;
  font-weight: 700;
  color: #fff;
  background-color: #076177;
  margin-right: 15px;
}
.shipment .shipment-list {
  clear: both;
  height: 75vh;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 30px 10px;
  margin: 30px 0 50px 0;
}
.shipment .shipment-list .shipment-list-wrap {
  height: 100%;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-h3-btn-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-h3-btn-wrap .shipment-list-btn {
  margin-right: 15px;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-h3-btn-wrap .shipment-list-btn button {
  width: 100px;
  height: 30px;
  line-height: 25px;
  font-weight: 700;
  color: #076177;
  border: 1px solid #d7d7d7;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-h3-btn-wrap .shipment-list-btn button:hover {
  color: #fff;
  background-color: #076177;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-h3-btn-wrap .shipment-list-btn button:nth-child(2) {
  margin: 0 10px;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-h3-btn-wrap .shipment-list-btn button:nth-child(3) {
  margin-right: 10px;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-table {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  height: 60vh;
  padding: 15px;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-table table {
  height: 95%;
  font-size: 0.8rem;
  text-align: center;
  border: 1px solid #d7d7d7;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-table table td {
  width: 300px;
  border-right: 1px solid #d7d7d7;
  padding: 10px;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-table table thead {
  font-weight: 700;
  background-color: #e9f5f7;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-table table thead td ul {
  display: flex;
  justify-content: space-between;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-table table tbody td {
  border-bottom: 1px solid #d7d7d7;
  background-color: #fff;
}
.shipment .shipment-list .shipment-list-wrap .shipment-list-table table tbody td ul {
  display: flex;
  justify-content: space-between;
}

.freetime {
  float: left;
  width: 100%;
}
.freetime .freetime-wrap {
  width: 100%;
  padding: 0 20px;
}
.freetime .freetime-wrap h2 {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 70px auto 30px auto;
}
.freetime .freetime-wrap h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 15px;
  padding-left: 20px;
}
.freetime .freetime-wrap .freetime-search {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 30px 10px 80px 10px;
}
.freetime .freetime-wrap .freetime-search .freetime-search-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.6rem;
  padding: 20px 15px;
}
.freetime .freetime-wrap .freetime-search .freetime-search-list li {
  width: 19.5%;
  height: 90px;
  border: 1px solid #d7d7d7;
}
.freetime .freetime-wrap .freetime-search .freetime-search-list li:nth-child(n+6) {
  margin-top: 12px;
}
.freetime .freetime-wrap .freetime-search .freetime-search-list li div {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
.freetime .freetime-wrap .freetime-search .freetime-search-list li div p {
  line-height: 35px;
  text-align: center;
  font-size: 1rem;
  margin-top: 5px;
}
.freetime .freetime-wrap .freetime-search .freetime-search-list li div .multi-select {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.freetime .freetime-wrap .freetime-search .freetime-search-list li div .ant-select-selection-item {
  width: 40%;
}
.freetime .freetime-wrap .freetime-search .freetime-search-btn {
  float: right;
  padding: 20px 0;
  font-size: 0.8rem;
  margin-right: 15px;
}
.freetime .freetime-wrap .freetime-search .freetime-search-btn label {
  margin-right: 10px;
}
.freetime .freetime-wrap .freetime-search .freetime-search-btn label input[type=checkbox] {
  vertical-align: middle;
  margin-right: 10px;
}
.freetime .freetime-wrap .freetime-search .freetime-search-btn > button {
  width: 80px;
  height: 30px;
  line-height: 25px;
  font-weight: 700;
  color: #fff;
  background-color: #076177;
}
.freetime .freetime-wrap .freetime-list {
  clear: both;
  height: 75vh;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 30px 10px;
  margin: 30px 0 50px 0;
}
.freetime .freetime-wrap .freetime-list .freetime-list-h3-btn-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.freetime .freetime-wrap .freetime-list .freetime-list-btn button {
  width: 100px;
  height: 30px;
  line-height: 25px;
  font-weight: 700;
  color: #076177;
  border: 1px solid #d7d7d7;
}
.freetime .freetime-wrap .freetime-list .freetime-list-btn button:hover {
  color: #fff;
  background-color: #076177;
}
.freetime .freetime-wrap .freetime-list .freetime-list-btn button:nth-child(2) {
  margin: 0 10px;
}
.freetime .freetime-wrap .freetime-list .freetime-list-btn button:nth-child(3) {
  margin-right: 10px;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table {
  height: 60vh;
  margin-top: 20px;
  padding: 15px;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table .added-background {
  background-color: rgba(234, 219, 190, 0.4) !important;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table .modified-background {
  background-color: rgba(146, 221, 158, 0.4) !important;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table .invalid-background {
  background-color: rgba(232, 181, 181, 0.4) !important;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table table {
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  text-align: center;
  border: 1px solid #d7d7d7;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table table td {
  width: 300px;
  border-right: 1px solid #d7d7d7;
  padding: 10px;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table table thead {
  font-weight: 700;
  background-color: #e9f5f7;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table table thead td ul {
  display: flex;
  justify-content: space-between;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table table tbody td {
  border-bottom: 1px solid #d7d7d7;
  background-color: #fff;
}
.freetime .freetime-wrap .freetime-list .freetime-list-table table tbody td ul {
  display: flex;
  justify-content: space-between;
}

.sr-master {
  float: left;
  width: 100%;
}
.sr-master .sr-master-wrap {
  width: 100%;
  padding: 0 20px;
}
.sr-master .sr-master-wrap h2 {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 70px auto 30px auto;
}
.sr-master .sr-master-wrap h3, .sr-master .sr-master-wrap h4 {
  font-size: 1rem;
  font-weight: 700;
  margin: 15px 0;
}
.sr-master .sr-master-wrap .sr-master-left {
  float: left;
  width: 40%;
  height: 95vh;
  font-size: 0.8rem;
  overflow-y: auto;
  padding: 1px 20px 30px 20px;
  margin-bottom: 50px;
}
.sr-master .sr-master-wrap .sr-master-left .sr-master-file-wrap {
  display: flex;
  flex-direction: column;
  height: 250px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}
.sr-master .sr-master-wrap .sr-master-left .sr-master-file-wrap p {
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  padding: 30px 0;
  border-bottom: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-master-left .sr-master-file-wrap p img {
  vertical-align: middle;
  margin-left: 15px;
}
.sr-master .sr-master-wrap .sr-master-left .sr-master-file-wrap button {
  margin: 50px 0;
}
.sr-master .sr-master-wrap .sr-master-left .sr-master-upload p {
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  margin-top: 10px;
  padding-left: 20px;
}
.sr-master .sr-master-wrap .sr-master-left .sr-master-upload button {
  height: 30px;
  line-height: 30px;
  color: #fff;
  float: right;
  margin: 10px 0;
  padding: 0 15px;
  background-color: #71b6c5;
  border-radius: 5px;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info {
  clear: both;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info .sr-info-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info .sr-info-title-wrap p {
  padding-left: 20px;
  font-weight: 700;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info .sr-info-title-wrap p img {
  vertical-align: middle;
  margin-right: 15px;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info .sr-info-title-wrap p span {
  color: #71b6c5;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info .sr-info-title-wrap button {
  width: 100px;
  height: 30px;
  color: #fff;
  background: url(../images/sr-icon4.png) no-repeat 90%;
  background-color: #71b6c5;
  border-radius: 5px;
  margin-right: 20px;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info .sr-info-title-wrap button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info ul {
  padding: 10px 20px;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info ul li {
  padding: 20px 0;
  border-bottom: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info ul li:last-child {
  border-bottom: 0px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-master-left .sr-info.active {
  border: 1.5px solid #71b6c5;
}
.sr-master .sr-master-wrap .sr-master-right {
  float: right;
  width: 59%;
  height: 95vh;
  font-size: 0.8rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 30px 20px;
  margin-bottom: 50px;
  overflow-y: auto;
}
.sr-master .sr-master-wrap .sr-master-right h3 {
  float: left;
}
.sr-master .sr-master-wrap .sr-master-right > button {
  float: right;
  width: 100px;
  height: 30px;
  border: 1px solid #d7d7d7;
  margin-bottom: 20px;
}
.sr-master .sr-master-wrap .sr-master-right > button:hover {
  color: #fff;
  background-color: #076177;
}
.sr-master .sr-master-wrap .sr-master-right .sr-shipper-name-address,
.sr-master .sr-master-wrap .sr-master-right .sr-consignee-name-address,
.sr-master .sr-master-wrap .sr-master-right .sr-notify-name-address {
  clear: both;
}
.sr-master .sr-master-wrap .sr-master-right .sr-shipper-name-address p,
.sr-master .sr-master-wrap .sr-master-right .sr-consignee-name-address p,
.sr-master .sr-master-wrap .sr-master-right .sr-notify-name-address p {
  margin: 15px 0;
}
.sr-master .sr-master-wrap .sr-master-right .sr-shipper-name-address input[type=text],
.sr-master .sr-master-wrap .sr-master-right .sr-consignee-name-address input[type=text],
.sr-master .sr-master-wrap .sr-master-right .sr-notify-name-address input[type=text] {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  margin-top: 10px;
  background-color: #edf1f2;
}
.sr-master .sr-master-wrap .sr-master-right .sr-shipper-name-address textarea,
.sr-master .sr-master-wrap .sr-master-right .sr-consignee-name-address textarea,
.sr-master .sr-master-wrap .sr-master-right .sr-notify-name-address textarea {
  width: 100%;
  height: 200px;
  padding: 20px;
  margin: 10px 0;
}
.sr-master .sr-master-wrap .sr-master-right .sr-shipper-name-address textarea:hover,
.sr-master .sr-master-wrap .sr-master-right .sr-consignee-name-address textarea:hover,
.sr-master .sr-master-wrap .sr-master-right .sr-notify-name-address textarea:hover {
  border: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-master-right .sr-consignee-name-address p,
.sr-master .sr-master-wrap .sr-master-right .sr-notify-name-address p {
  float: left;
}
.sr-master .sr-master-wrap .sr-master-right .sr-consignee-name-address label,
.sr-master .sr-master-wrap .sr-master-right .sr-notify-name-address label {
  float: right;
}
.sr-master .sr-master-wrap .sr-master-right .sr-consignee-name-address label input[type=checkbox],
.sr-master .sr-master-wrap .sr-master-right .sr-notify-name-address label input[type=checkbox] {
  margin-left: 10px;
  vertical-align: middle;
}
.sr-master .sr-master-wrap .sr-shipment-info ul {
  width: 100%;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li p {
  line-height: 30px;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li p:first-child {
  min-width: 30%;
  margin-top: 7px;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li .ant-select {
  min-width: 70%;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li:nth-child(6) .ant-input {
  width: 34%;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li:nth-child(6) .ant-input:last-child {
  margin-left: 1%;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li:nth-child(7) p {
  line-height: 1.2rem;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li:nth-child(7) .ant-picker {
  min-width: 34%;
}
.sr-master .sr-master-wrap .sr-shipment-info ul li:nth-child(7) .ant-picker:last-child {
  margin-left: 1%;
}
.sr-master .sr-master-wrap .sr-shipment-info .sr-shipment-info-select {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.sr-master .sr-master-wrap .sr-shipment-info .sr-shipment-info-select li {
  width: 28%;
}
.sr-master .sr-master-wrap .sr-shipment-info .sr-shipment-info-select li .ant-select {
  min-width: 38.5%;
}
.sr-master .sr-master-wrap .sr-shipment-info .sr-shipment-info-select li:nth-child(2) {
  width: 40%;
  margin: 0 10px;
}
.sr-master .sr-master-wrap .sr-shipment-info .sr-shipment-info-select li:nth-child(2) .ant-select {
  min-width: 60%;
}
.sr-master .sr-master-wrap .sr-cargo-info {
  padding-top: 10px;
  border-top: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-cargo-info ul {
  margin-bottom: 50px;
}
.sr-master .sr-master-wrap .sr-cargo-info ul li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.sr-master .sr-master-wrap .sr-cargo-info ul li p {
  min-width: 30%;
  line-height: 30px;
}
.sr-master .sr-master-wrap .sr-cargo-info ul li .ant-input {
  width: 70%;
}
.sr-master .sr-master-wrap .sr-cargo-info ul li:nth-child(2) .ant-select {
  min-width: 20%;
  margin-left: 10px;
}
.sr-master .sr-master-wrap .sr-cargo-info .sr-result {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.sr-master .sr-master-wrap .sr-cargo-info .sr-result textarea {
  height: 200px;
}
.sr-master .sr-master-wrap .sr-cargo-info .sr-result textarea:first-child {
  width: 20%;
}
.sr-master .sr-master-wrap .sr-cargo-info .sr-result textarea:last-child {
  width: 79%;
}
.sr-master .sr-master-wrap .sr-container-list {
  margin-top: 20px;
}
.sr-master .sr-master-wrap .sr-container-list .sr-container-list-h4-btn {
  display: flex;
  justify-content: space-between;
}
.sr-master .sr-master-wrap .sr-container-list .sr-container-list-h4-btn .sr-container-list-btn {
  display: flex;
}
.sr-master .sr-master-wrap .sr-container-list .sr-container-list-h4-btn .sr-container-list-btn p,
.sr-master .sr-master-wrap .sr-container-list .sr-container-list-h4-btn .sr-container-list-btn button {
  width: 50px;
  height: 30px;
  border: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-container-list .sr-container-list-h4-btn .sr-container-list-btn p {
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
}
.sr-master .sr-master-wrap .sr-container-list table {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-container-list table thead {
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #edf1f2;
}
.sr-master .sr-master-wrap .sr-container-list table thead td {
  border-right: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-container-list table thead td:first-child {
  width: 5%;
}
.sr-master .sr-master-wrap .sr-container-list table thead td:first-child span {
  display: none;
}
.sr-master .sr-master-wrap .sr-container-list table thead td:nth-child(2) {
  width: 35%;
}
.sr-master .sr-master-wrap .sr-container-list table thead td:nth-child(3) {
  width: 30%;
}
.sr-master .sr-master-wrap .sr-container-list table thead td:nth-child(4), .sr-master .sr-master-wrap .sr-container-list table thead td:nth-child(5) {
  width: 10%;
}
.sr-master .sr-master-wrap .sr-container-list tbody tr {
  height: 30px;
  line-height: 30px;
}
.sr-master .sr-master-wrap .sr-container-list tbody tr td {
  padding: 0 5px;
  border-right: 1px solid #d7d7d7;
}
.sr-master .sr-master-wrap .sr-container-list tbody tr td:first-child {
  width: 5%;
}
.sr-master .sr-master-wrap .sr-container-list tbody tr td:nth-child(2) {
  width: 35%;
}
.sr-master .sr-master-wrap .sr-container-list tbody tr td:nth-child(3) {
  width: 30%;
}
.sr-master .sr-master-wrap .sr-container-list tbody tr td:nth-child(4), .sr-master .sr-master-wrap .sr-container-list tbody tr td:nth-child(5) {
  width: 10%;
}
.sr-master .sr-master-wrap .sr-container-list tbody tr td .ant-input {
  height: 25px;
}
.sr-master .sr-master-wrap .sr-container-list tbody tr td select {
  width: 100%;
  border: 1px solid #d9d9d9;
}

.booking-master-tab {
  float: left;
}
.booking-master-tab button {
  width: 140px;
  height: 30px;
  text-align: center;
  color: #292929;
  background-color: #fff;
  border: 1px solid #d7d7d7;
}
.booking-master-tab button.active {
  color: #fff;
  background-color: #086277;
}

.booking-master-close {
  position: absolute;
  right: 0;
  margin-top: -78px;
  margin-right: 30px;
  color: #d7d7d7;
  font-size: 30px;
  font-weight: 700;
}
.booking-master-close:hover {
  color: #292929;
}

.booking-master-btn {
  float: right;
}
.booking-master-btn button {
  width: 60px;
  height: 30px;
  border: 1px solid #d7d7d7;
  color: #086277;
}
.booking-master-btn button:hover {
  color: #fff;
  background-color: #086277;
}
.booking-master-btn button:nth-child(2) {
  margin: 0 5px;
}

.booking-master-list {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  padding: 20px;
  border-top: 1px solid #d7d7d7;
}
.booking-master-list input {
  min-width: 43%;
  height: 30px;
  padding-left: 10px;
  border: 1px solid #d7d7d7;
}

.booking-list1 li,
.booking-list2 li {
  display: flex;
  align-items: center;
  height: 30px;
}
.booking-list1 li p,
.booking-list2 li p {
  min-width: 38%;
  line-height: 30px;
  padding-top: 7px;
  padding-left: 10px;
}
.booking-list1 li:nth-child(even),
.booking-list2 li:nth-child(even) {
  margin: 7px 0;
}
.booking-list1 li:first-child,
.booking-list2 li:first-child {
  margin-top: 10px;
}

.booking-list1 {
  width: 49%;
}
.booking-list1 li:nth-child(8) .ant-select {
  min-width: 62%;
}
.booking-list1 li:nth-child(11) input, .booking-list1 li:nth-child(12) input {
  border: 0;
  padding-left: 0;
}
.booking-list1 li:nth-child(11) .ant-picker, .booking-list1 li:nth-child(12) .ant-picker {
  height: 30px;
  min-width: 62%;
}

.booking-list2-wrap {
  width: 49%;
}

.booking-list2 li:first-child .ng-pristine {
  min-width: 35%;
}
.booking-list2 li:first-child button {
  min-width: 16%;
  height: 30px;
  color: #086277;
  font-size: 12px;
  border: 1px solid #d7d7d7;
  margin-top: 6px;
  margin-left: 5px;
}
.booking-list2 li:first-child button:hover {
  color: #fff;
  background-color: #086277;
}
.booking-list2 li:nth-child(5) .ng-pristine {
  min-width: 15%;
}
.booking-list2 li:nth-child(5) .ng-valid {
  min-width: 43%;
  padding-right: 5px;
  margin-left: 5px;
}
.booking-list2 li:last-child {
  margin-top: 20px;
}
.booking-list2 li:last-child p {
  margin-top: 10px;
}

.container-info {
  width: 100%;
  height: 203px;
  margin-top: 20px;
  overflow-y: auto;
}
.container-info p {
  float: left;
  padding-left: 10px;
}
.container-info .container-add-btn {
  float: right;
  width: 40px;
  height: 30px;
  color: #086277;
  border: 1px solid #d7d7d7;
  margin-bottom: 3px;
}
.container-info .container-add-btn:hover {
  color: #fff;
  background-color: #086277;
}
.container-info table {
  clear: both;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 1px solid #d7d7d7;
}
.container-info table td {
  padding: 3px 0;
  width: 30%;
}
.container-info table td:nth-child(2) {
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
}
.container-info table td:last-child {
  width: 10%;
  border-left: 1px solid #d7d7d7;
}
.container-info table td.valid-error {
  border-color: #FF0000 !important;
}
.container-info table thead {
  font-size: 0.6rem;
  background-color: #d7d7d7;
  border: 1px solid #d7d7d7;
}
.container-info table tbody td {
  width: 30%;
  border-bottom: 1px solid #d7d7d7;
}
.container-info table tbody td select {
  width: 100%;
  font-size: 0.6rem;
  text-align: center;
  border: 0;
}
.container-info table tbody td:nth-child(3) input[type=text] {
  width: 100%;
  text-align: center;
}
.container-info table tbody td:last-child {
  width: 10%;
}

.bl-master-list {
  clear: both;
  height: 65vh;
  border-top: 1px solid #d7d7d7;
  font-size: 0.8rem;
  padding: 20px;
  overflow-y: auto;
}
.bl-master-list h4 {
  font-size: 0.8rem;
  font-weight: 700;
  margin-bottom: 15px;
}
.bl-master-list .bl-master-info > h4 {
  float: left;
}
.bl-master-list .bl-master-info .bl-master-info-check {
  float: right;
}
.bl-master-list .bl-master-info .bl-master-info-check label:last-child {
  margin-left: 20px;
}
.bl-master-list .bl-master-info .bl-master-info-check label input[type=checkbox] {
  vertical-align: middle;
}

.bl-master-info-input {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.bl-master-info-input li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bl-master-info-input li p {
  line-height: 30px;
  padding-top: 7px;
}
.bl-master-info-input li:nth-child(even) {
  margin: 7px 0;
}
.bl-master-info-input input[type=text],
.bl-master-info-input select {
  height: 30px;
  padding-left: 20px;
  border: 1px solid #d7d7d7;
}
.bl-master-info-input .bl-master-info-ul1 {
  width: 45%;
}
.bl-master-info-input .bl-master-info-ul1 p {
  min-width: 40%;
}
.bl-master-info-input .bl-master-info-ul-select {
  width: 52%;
}
.bl-master-info-input .bl-master-info-ul-select button {
  min-width: 15%;
  height: 30px;
  color: #076177;
  border: 1px solid #d7d7d7;
  margin-top: 7px;
  margin-left: 5px;
}
.bl-master-info-input .bl-master-info-ul-select button:hover {
  color: #fff;
  background-color: #076177;
}
.bl-master-info-input .bl-master-info-ul-select .bl-master-info-ul2 li p {
  min-width: 45%;
}
.bl-master-info-input .bl-master-info-ul-select .bl-master-info-ul2 li:first-child input[type=text] {
  width: 28%;
}
.bl-master-info-input .bl-master-info-ul-select .bl-master-info-ul2 li:last-child select {
  width: 50%;
}

.bl-header {
  padding-top: 20px;
}
.bl-header .bl-shipper-name-address,
.bl-header .bl-consignee-name-address,
.bl-header .bl-notify-name-address {
  clear: both;
}
.bl-header .bl-shipper-name-address input[type=text],
.bl-header .bl-consignee-name-address input[type=text],
.bl-header .bl-notify-name-address input[type=text] {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  margin-top: 10px;
  background-color: #edf1f2;
}
.bl-header .bl-shipper-name-address textarea,
.bl-header .bl-consignee-name-address textarea,
.bl-header .bl-notify-name-address textarea {
  width: 100%;
  height: 200px;
  padding: 20px;
  margin: 10px 0;
  background-color: #edf1f2;
}
.bl-header .bl-consignee-name-address p,
.bl-header .bl-notify-name-address p {
  float: left;
}
.bl-header .bl-consignee-name-address label,
.bl-header .bl-notify-name-address label {
  float: right;
}
.bl-header .bl-consignee-name-address label input[type=checkbox],
.bl-header .bl-notify-name-address label input[type=checkbox] {
  margin-left: 10px;
  vertical-align: middle;
}

.bl-shipment-info h4 {
  margin: 10px 0;
}
.bl-shipment-info ul {
  width: 100%;
}
.bl-shipment-info ul li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3px 0;
}
.bl-shipment-info ul li p {
  height: 25px;
  min-width: 20%;
  margin-top: 10px;
}
.bl-shipment-info ul li .ant-select {
  min-width: 75%;
}
.bl-shipment-info ul li .ant-input,
.bl-shipment-info ul li .ant-picker {
  width: 35%;
}
.bl-shipment-info ul li:nth-child(7) {
  padding-top: 6px;
  margin-bottom: 10px;
}
.bl-shipment-info ul li:nth-child(7) p {
  line-height: 0.9rem;
  margin-top: 0px;
}
.bl-shipment-info .bl-shipment-info-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.bl-shipment-info .bl-shipment-info-select li:first-child, .bl-shipment-info .bl-shipment-info-select li:last-child {
  width: 28%;
}
.bl-shipment-info .bl-shipment-info-select li:first-child .ant-select, .bl-shipment-info .bl-shipment-info-select li:last-child .ant-select {
  min-width: 45%;
}
.bl-shipment-info .bl-shipment-info-select li:nth-child(2) {
  width: 40%;
  margin: 0 10px;
}
.bl-shipment-info .bl-shipment-info-select li:nth-child(2) .ant-select {
  min-width: 65%;
}

.bl-cargo-info ul {
  margin-bottom: 50px;
}
.bl-cargo-info ul li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.bl-cargo-info ul li:nth-child(2) .ant-input {
  width: 50%;
}
.bl-cargo-info ul li:nth-child(2) .ant-select {
  width: 23%;
  margin-left: 20px;
}
.bl-cargo-info ul li p {
  min-width: 25%;
  line-height: 30px;
}

.bl-description {
  width: 100%;
}
.bl-description div textarea {
  height: 80px;
  padding: 20px;
}

.bl-container-list {
  margin-top: 20px;
}
.bl-container-list .bl-container-list-h4-btn {
  display: flex;
  justify-content: space-between;
}
.bl-container-list .bl-container-list-h4-btn .bl-container-list-btn {
  display: flex;
}
.bl-container-list .bl-container-list-h4-btn .bl-container-list-btn p,
.bl-container-list .bl-container-list-h4-btn .bl-container-list-btn button {
  width: 50px;
  height: 30px;
  color: #076177;
  border: 1px solid #d7d7d7;
}
.bl-container-list .bl-container-list-h4-btn .bl-container-list-btn p {
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
}
.bl-container-list .bl-container-list-h4-btn .bl-container-list-btn button:hover {
  color: #fff;
  background-color: #076177;
}
.bl-container-list table {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #d7d7d7;
}
.bl-container-list table thead {
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #edf1f2;
}
.bl-container-list table thead td {
  border-right: 1px solid #d7d7d7;
}
.bl-container-list table thead td:first-child {
  width: 5%;
}
.bl-container-list table thead td:first-child span {
  display: none;
}
.bl-container-list table thead td:nth-child(2) {
  width: 35%;
}
.bl-container-list table thead td:nth-child(3) {
  width: 30%;
}
.bl-container-list table thead td:nth-child(4), .bl-container-list table thead td:nth-child(5) {
  width: 10%;
}
.bl-container-list tbody {
  border-top: 1px solid #d7d7d7;
}
.bl-container-list tbody tr {
  height: 30px;
  line-height: 30px;
}
.bl-container-list tbody tr td {
  padding: 0 5px;
  border-right: 1px solid #d7d7d7;
}
.bl-container-list tbody tr td:first-child {
  width: 5%;
}
.bl-container-list tbody tr td:nth-child(2) {
  width: 35%;
}
.bl-container-list tbody tr td:nth-child(3) {
  width: 30%;
}
.bl-container-list tbody tr td:nth-child(4), .bl-container-list tbody tr td:nth-child(5) {
  width: 10%;
}
.bl-container-list tbody tr td .ant-input {
  height: 25px;
}
.bl-container-list tbody tr td select {
  width: 100%;
  border: 1px solid #d9d9d9;
}

.ant-input,
.ant-select {
  height: 30px;
  font-size: 0.8rem;
  margin-top: 7px;
}

.sr-send-modal {
  height: 63vh;
  padding: 0 20px 20px 20px;
  overflow-y: auto;
}
.sr-send-modal button {
  float: right;
  width: 100px;
  height: 30px;
  color: #fff;
  background-color: #076177;
  margin-bottom: 20px;
}
.sr-send-modal .sr-send-content {
  clear: both;
  cursor: pointer;
}
.sr-send-modal .sr-send-content .sr-send-company-list {
  width: 100%;
  background-color: #edf1f2;
  padding: 10px;
  margin-top: 20px;
}
.sr-send-modal .sr-send-content .sr-send-company-list p {
  height: 40px;
  line-height: 40px;
}
.sr-send-modal .sr-send-content .sr-send-company-list.active {
  border: 1.5px solid #076177;
}

.admin-dashboard {
  float: left;
  width: 100%;
}
.admin-dashboard .admin-dashboard-wrap {
  width: 100%;
  padding: 0 20px;
}
.admin-dashboard .admin-dashboard-wrap h2 {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 70px auto 30px auto;
}
.admin-dashboard .admin-dashboard-wrap h3 {
  font-size: 1rem;
  font-weight: 700;
  margin: 40px 0;
}

.admin-dashboard-select {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  padding: 30px 25px;
}
.admin-dashboard-select ul {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
}
.admin-dashboard-select ul li {
  display: flex;
  justify-content: space-between;
  width: 24%;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  border: 1px solid #d7d7d7;
}
.admin-dashboard-select ul li p {
  width: 35%;
}
.admin-dashboard-select ul li .ant-picker {
  min-width: 75%;
  height: 33px;
  margin-top: 7px;
}
.admin-dashboard-select ul li .ant-select {
  min-width: 75%;
}

.admin-dashboard-first {
  padding-bottom: 50px;
}
.admin-dashboard-first .current-issue {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.admin-dashboard-first .current-issue .current-issue1 {
  min-width: 32.5%;
  font-size: 0.8rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  margin: 10px 1px;
}
.admin-dashboard-first .current-issue .current-issue1:not(:first-child) {
  margin-left: 1%;
}
.admin-dashboard-first .current-issue .current-issue1 > p {
  height: 50px;
  line-height: 50px;
  font-size: 1rem;
  font-weight: 700;
  padding-left: 20px;
}
.admin-dashboard-first .current-issue .current-issue1 > p span {
  color: #71b6c5;
}
.admin-dashboard-first .current-issue .current-issue1 ul li {
  border-top: 1px solid #d7d7d7;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}
.admin-dashboard-first .current-issue .current-issue1 ul li p img {
  width: 20px;
  vertical-align: middle;
  margin-right: 10px;
}
.admin-dashboard-first .current-issue .current-issue1 ul li:nth-child(2) p:nth-child(1) {
  line-height: 40px;
}
.admin-dashboard-first .current-issue .current-issue1 ul li:nth-child(2) p:nth-child(2) {
  width: 70%;
  height: 40px;
  text-align: right;
}
.admin-dashboard-first .current-issue .current-issue1 ul li:nth-child(4) p:nth-child(2) {
  width: 70%;
}
.admin-dashboard-first .current-issue .current-issue1 ul li:nth-child(4) p:nth-child(2) .ant-select {
  margin-top: -5px;
}
.admin-dashboard-first .current-issue-arrow {
  width: 110px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-dashboard-first .current-issue-arrow button .fa-solid {
  font-size: 1.6rem;
  color: #71b6c5;
}
.admin-dashboard-first .current-issue-arrow button:disabled {
  color: #ccc;
  cursor: not-allowed;
}
.admin-dashboard-first .current-issue-arrow p span {
  color: #71b6c5;
  font-weight: 700;
}

.admin-dashboard-second {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  margin-top: 30px;
  padding: 40px 20px;
  overflow-x: auto;
}
.admin-dashboard-second .performance-trend,
.admin-dashboard-second .total-revenue,
.admin-dashboard-second .total-cost {
  font-size: 0.8rem;
  width: 32%;
  border: 1px solid red;
}
.admin-dashboard-second .performance-trend h3,
.admin-dashboard-second .total-revenue h3,
.admin-dashboard-second .total-cost h3 {
  float: left;
}

.admin-dashboard-third {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  margin-top: 30px;
  padding: 40px 20px;
  overflow-x: auto;
}
.admin-dashboard-third .volume-country,
.admin-dashboard-third .volume-carrier,
.admin-dashboard-third .performance-trend-country {
  width: 32%;
}
.admin-dashboard-third .volume-country h3,
.admin-dashboard-third .volume-carrier h3,
.admin-dashboard-third .performance-trend-country h3 {
  float: left;
}

.admin-dashboard-four {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  margin: 30px 0 50px 0;
  padding: 40px 20px 60px 20px;
  overflow-x: auto;
}
.admin-dashboard-four .performance-comparison,
.admin-dashboard-four .performance-trend-item,
.admin-dashboard-four .performance-status {
  width: 32%;
}
.admin-dashboard-four .performance-comparison h3,
.admin-dashboard-four .performance-trend-item h3,
.admin-dashboard-four .performance-status h3 {
  float: left;
}

.admin-chart {
  width: 370px;
}

.email-helper {
  height: 370px;
}
.email-helper p {
  margin-bottom: 20px;
}
.email-helper input {
  width: 100%;
  height: 30px;
  border: 1px solid #d9d9d9;
}
.email-helper .ant-input {
  margin-top: 0;
  padding: 10px 20px;
  border: 1px solid #d9d9d9;
}
.email-helper textarea {
  width: 100%;
  height: 200px;
  border: 0px solid #d9d9d9;
  overflow-y: auto;
}

.ant-input:focus {
  border-color: #086277;
}

.ant-input-suffix {
  margin-left: 0;
}

.anticon.ant-input-clear-icon {
  padding-right: 10px;
}

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 10px;
}

.ant-input-affix-wrapper {
  padding: 0;
  margin-bottom: 10px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #086277;
}

.email-helper-btn {
  float: right;
  margin-top: 20px;
}
.email-helper-btn button {
  width: 100px;
  height: 30px;
  color: #fff;
  background-color: #72b6c6;
}
.email-helper-btn button:first-child {
  margin-right: 10px;
}
.email-helper-btn button:last-child span {
  margin-right: 18px;
  margin-left: 20px;
}
.email-helper-btn button:last-child img {
  width: 16px;
  vertical-align: middle;
}