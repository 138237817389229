@charset "utf-8";

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:after,
  &:before {
    box-sizing: border-box;
    pointer-events: none;
  }
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}

a{
    color: inherit;
    text-decoration: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

button {
	border: 0;
	background: none;
	font-size: 0.8rem;
	cursor: pointer;
}

input {
	border: 0;
	outline: none;
	font-size: 0.8rem;
}

select {
	outline: none;
	font-size: 0.8rem;
}


// webkit scroll bar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: #72b6c6;
  border: 0 none transparent;
  &:hover {
    background: #72b6c6;
  }
  &:active {
    background: #72b6c6;
  }
}
::-webkit-scrollbar-track {
  background: #e0e0e0;
  border: 0 none transparent;
  &:hover {
    background: #e0e0e0;
  }
  &:active {
    background: #e0e0e0;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}

#content-corechat {
  min-width: 1500px;
  min-height: 100vh;
  display: flex;
  overflow: auto;
}

#content {
  position: relative;
  top: 0;
  left: 0;
  width: 78vw;
  height: 100vh;
  flex-grow: 1;
  overflow-y: auto;
  background-color: #f5f5f5;
}