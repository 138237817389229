@charset "UTF-8";

.booking-master-tab {
	float: left;
	button {
		width: 140px;
		height: 30px;
		text-align: center;
		color: #292929;
		background-color: #fff;
		border: 1px solid #d7d7d7;
		&.active {
			color: #fff;
			background-color: #086277;
		}
	}
}

.booking-master-close {
	position: absolute;
	right: 0;
	margin-top: -78px;
	margin-right: 30px;
	color: #d7d7d7;
	font-size: 30px;
	font-weight: 700;
	&:hover {
		color: #292929;
	}
}

.booking-master-btn {
	float: right;
	button {
		width: 60px;
		height: 30px;
		border: 1px solid #d7d7d7;
		color: #086277;
		&:hover {
			color: #fff;
			background-color: #086277;
		}
		&:nth-child(2) {
			margin: 0 5px;
		}
	}
}

.booking-master-list {
	clear: both;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	font-size: 12px;
	padding: 20px;
	border-top: 1px solid #d7d7d7;
	input {
		min-width: 43%;
		height: 30px;
		padding-left: 10px;
		border: 1px solid #d7d7d7;
	}
}

.booking-list1,
.booking-list2 {
	li {
		display: flex;
		align-items: center;
		height: 30px;
		p {
			min-width: 38%;
			line-height: 30px;
			padding-top: 7px;
			padding-left: 10px;
		}
		&:nth-child(even) {
			margin: 7px 0;
		}
		&:first-child {
			margin-top: 10px;
		}
	}
}

.booking-list1 {
	width: 49%;
	li {
		&:nth-child(8) {
			.ant-select {
				min-width: 62%;
			}
		}
		&:nth-child(11),
		&:nth-child(12) {
			input {
				border: 0;
				padding-left: 0;
			}
			.ant-picker {
				height: 30px;
				min-width: 62%;
			}
		}
	}
}
.booking-list2-wrap {
	width: 49%;
}
.booking-list2 {
	li{
		&:first-child {
			.ng-pristine {
				min-width: 35%;
			}
			button {
				min-width: 16%;
				height: 30px;
				color: #086277;
				font-size: 12px;
				border: 1px solid #d7d7d7;
				margin-top: 6px;
				margin-left: 5px;
			&:hover {
				color: #fff;
				background-color: #086277;
				}
			}
		}
		&:nth-child(5) {
			.ng-pristine {
				min-width: 15%;
			}
			.ng-valid {
				min-width: 43%;
				padding-right: 5px;
				margin-left: 5px;
			}
		}
		&:last-child {
			margin-top: 20px;
			p {
				margin-top: 10px;
			}
		}
	}
}



.container-info {
	width: 100%;
	height: 203px;
	margin-top: 20px;
	overflow-y: auto;
	p {
		float: left;
		padding-left: 10px;
	}
	.container-add-btn {
		float: right;
		width: 40px;
		height: 30px;
		color: #086277;
		border: 1px solid #d7d7d7;
		margin-bottom: 3px;
		&:hover {
			color: #fff;
			background-color: #086277;
		}
	}
	table {
		clear: both;
		border-collapse: collapse;
		width: 100%;
		text-align: center;
		border: 1px solid #d7d7d7;
		td {
			padding: 3px 0;
			width: 30%;
			&:nth-child(2) {
				border-left: 1px solid #d7d7d7;
				border-right: 1px solid #d7d7d7;
			}
			&:last-child {
				width: 10%;
				border-left: 1px solid #d7d7d7;
			}
			&.valid-error {
				border-color: #FF0000 unquote("!important");
			}
		}
		thead {
			font-size: 0.6rem;
			background-color: #d7d7d7;
			border: 1px solid #d7d7d7;
		}
		tbody {
			td {
				width: 30%;
				border-bottom: 1px solid #d7d7d7;
				select {
					width: 100%;
					font-size: 0.6rem;
					text-align: center;
					border: 0;
				}
				&:nth-child(3) {
					input[type="text"] {
						width: 100%;
						text-align: center;
					}
				}
				&:last-child {
					width: 10%;
				}
			}
		}
	}
}


