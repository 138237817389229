@charset "utf-8";

.email-helper {
	height: 370px;
	p {
		margin-bottom: 20px;
	}
	input {
		width: 100%;
		height: 30px;
		border: 1px solid #d9d9d9;
	}
	.ant-input {
		margin-top: 0;
		padding: 10px 20px;
		border: 1px solid #d9d9d9;
	}
	textarea {
		width: 100%;
		height: 200px;
		border: 0px solid #d9d9d9;
		overflow-y: auto;
	}
}

.ant-input:focus {
	border-color: #086277;
}

.ant-input-suffix {
	margin-left: 0;
}
.anticon.ant-input-clear-icon {
	padding-right: 10px;
}

.ant-input-affix-wrapper>input.ant-input {
	padding-left: 10px;
}
.ant-input-affix-wrapper {
	padding: 0;
	margin-bottom: 10px;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-color: #086277;
}



.email-helper-btn {
	float: right;
	margin-top: 20px;
	button {
		width: 100px;
		height: 30px;
		color: #fff;
		background-color: #72b6c6;
		&:first-child {
			margin-right: 10px;
		}
		&:last-child {
			span {
			margin-right: 18px;
			margin-left: 20px;
			}
			img {
				width: 16px;
				vertical-align: middle;
			}
		}
	}
}