@charset "UTF-8";

// common ////////////////////////////////
.freetime {
	float: left;
	width: 100%;
	.freetime-wrap {
		width: 100%;
		padding: 0 20px;
		h2 {
			width: 100%;
			height: 60px;
			line-height: 60px;
			font-size: 1.2rem;
			font-weight: 700;
			margin: 70px auto 30px auto;
		}
		h3 {
			font-size: 1rem;
			font-weight: 700;
			margin-bottom: 15px;
			padding-left: 20px;
		} /////////////////////////////////////

		.freetime-search {
				border-radius: 5px;
				background-color: #fff;
				box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
				padding: 30px 10px 80px 10px;
			.freetime-search-list {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				font-size: 0.6rem;
				padding: 20px 15px;
				li {
					width: 19.5%;
					height: 90px;
					border: 1px solid #d7d7d7;
					&:nth-child(n+6) {
						margin-top: 12px;
					}
					div {
						display: flex;
						flex-direction: column;
						padding: 0 15px;
						p {
							line-height: 35px;
							text-align: center;
							font-size: 1rem;
							margin-top: 5px;
						}
							.multi-select {
								width: 100%;
								text-overflow: ellipsis;
								overflow: hidden;
							}
							.ant-select-selection-item {
								width: 40%;
							}
					}
				}
			}
			.freetime-search-btn {
				float: right;
				padding: 20px 0;
				font-size: 0.8rem;
				margin-right: 15px;
				label {
					margin-right: 10px;
					input[type="checkbox"] {
						vertical-align: middle;
						margin-right: 10px;
					}
				}
				>button {
					width: 80px;
					height: 30px;
					line-height: 25px;
					font-weight: 700;
					color: #fff;
					background-color: #076177;
				}
			}
		}
.freetime-list {
		clear: both;
		height: 75vh;
		border-radius: 5px;
		background-color: #fff;
		box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
		padding: 30px 10px;
		margin: 30px 0 50px 0;
		.freetime-list-h3-btn-wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.freetime-list-btn {
			button {
				width: 100px;
				height: 30px;
				line-height: 25px;
				font-weight: 700;
				color: #076177;
				border: 1px solid #d7d7d7;
				&:hover {
					color: #fff;
					background-color: #076177;
				}
				&:nth-child(2) {
					margin: 0 10px;
				}
				&:nth-child(3) {
					margin-right: 10px;
				}
			}
		}
		.freetime-list-table {
			height: 60vh;
			margin-top: 20px;
			padding: 15px;
			.added-background {
				background-color: rgba(234, 219, 190, 0.4) !important;
			}
			.modified-background {
				background-color: rgba(146, 221, 158, 0.4) !important;
			}
			.invalid-background {
				background-color: rgba(232, 181, 181, 0.4) !important;
			}
			table {
				width: 100%;
				height: 100%;
				font-size: 0.8rem;
				text-align: center;
				border: 1px solid #d7d7d7;
				td {
					width: 300px;
					border-right: 1px solid #d7d7d7;
					padding: 10px;
				}
				thead {
					font-weight: 700;
					background-color: #e9f5f7;
					td {
						ul {
							display: flex;
							justify-content: space-between;
						}
					}
				}
				tbody {
					td {
						border-bottom: 1px solid #d7d7d7;
						background-color: #fff;
						ul {
							display: flex;
							justify-content: space-between;
						}
					}
				}
			}
		}
	}
	}
}

