@charset "utf-8";

#header {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 50;
	background-color: #fff;
	box-shadow: 0px 0px 3px rgb(0,0,0,0.2);
	.header-wrap {
		display: flex;
		justify-content: space-between;
		height: 50px;
		border-bottom: 1px solid #d5d5d5;
		overflow: hidden;
		background-color: #fff;
		h1 {
			margin: 15px 80px 0 20px;
			img {
				width: 150px;
			}
		}
		.gnb {
			display: flex;
			font-size: 1rem;
			margin-top: 17px;
			background-color: #fff;
			>li {
				font-weight: 700;
				color: #292929;
				>a {
					text-align: center;
					display: block;
					height: 55px;
					&:hover {
						color: #086277;
					}
					&.active1 {
						color: #086277;
					}
				}
				.two-depth {
					li {
						font-weight: 500;
						height: 40px;
						line-height: 40px;
						a {
							display: block;
							text-align: center;
							&:hover {
								color: #086277;
							}
							&.active2 {
								color: #086277;
								font-weight: 700;
							}
						}
					}
				}
				&:nth-child(2) {
					margin: 0 20px;
				}
			}
		}
	}
}

.util-menu {
	margin-right: 30px;
	ul {
		display: flex;
		flex-direction: row;
		margin-top: 17px;
		li {
			font-size: 1rem;
			font-weight: 700;
			&:first-child {
				span {
					padding: 0 30px;
				}
					&:after {
					content: "|";
					margin: 0 20px;
				}
			}
			&:nth-child(2) {
				margin-right: 30px;
				button {
					font-size: 1rem;
					font-weight: 700;
					color: #086277;
				}
			}
			&:last-child {
				margin-top: -10px;
				button {
					width: 120px;
					height: 35px;
					line-height: 35px;
					font-weight: 700;
					color: rgb(232, 244, 255);
					border-radius: 5px;
					background-color: #086277;
				}
				img {
					display: none;
					width: 20%;
					vertical-align: -10%;
					margin-right: 10px;
				}
				span {
					font-size: 1rem;
					color: rgb(112, 190, 207);
				}
			}
		}
	}
}