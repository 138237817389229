@charset "UTF-8";

// flex로 가운데 정렬 @mixin
@mixin flex-center {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/// 이미지 가운데 정렬 @mixin
@mixin image-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin: auto;
}
